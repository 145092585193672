<template>
  <v-container fluid>
    <v-row>
      <v-breadcrumbs :items="breadCrumbsItems" large>
        <template v-slot:divider>
          <v-icon size="11">mdi-greater-than</v-icon>
        </template>
      </v-breadcrumbs>
    </v-row>

    <v-layout wrap>
      <v-col cols="12" md="3">
        <RetailerMenu></RetailerMenu>
      </v-col>

      <v-col cols="12" md="9" class="mt-2">
        <v-row class="rowBg">
          <v-col cols="12">
            <h5>
              <v-icon size="25" color="#2776ED" class="mr-4"
                >mdi-information</v-icon
              >
              qikPharma | Help Center
            </h5>
          </v-col>
        </v-row>

        <v-row wrap class="mt-10 mb-10">
          <v-col cols="12" class="rowBg">
            <v-layout wrap class="paddingExtra">
              <v-col cols="12" md="4" class="mt-5">
                <h3>Hi,</h3>
                <p>What can we help you with?</p>
              </v-col>
              <v-col cols="12" md="4" align="center">
                <v-img
                  alt="Referal logo"
                  :src="'img/helpCenter.png'"
                  width="100px"
                />
              </v-col>
              <v-col cols="12" md="4">
                <table>
                  <tr v-for="(faqs, index) in quickFAQs" :key="index">
                    <td style="padding:10px;">
                      <v-icon color="#BD2121" size="20">mdi-information</v-icon>
                    </td>
                    <td>
                      {{ faqs }}
                    </td>
                  </tr>
                </table>
              </v-col>
            </v-layout>

            <v-layout wrap>
              <v-col cols="11" md="11" class="whiteBg ml-2">
                <v-row>
                  <v-col cols="12" md="6">
                    <h5>Frequently Asked Questions</h5>
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                    align="right"
                    class="hidden-sm-and-down"
                  >
                    <h5>More Questions <v-icon>mdi-arrow-right</v-icon></h5>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12">
                    <v-layout
                      text
                      color="#000000"
                      v-for="(faq, index) in FAQs"
                      :key="index"
                      class="mt-3"
                    >
                      <v-icon color="#BD2121" size="20" class="pr-3"
                        >mdi-information</v-icon
                      >
                      {{ faq.question }}
                    </v-layout>
                  </v-col>
                </v-row>
              </v-col>
            </v-layout>

            <v-layout wrap class="mt-4 mb-5">
              <v-col cols="11" md="11" class="whiteBg ml-2">
                <h4>Popular Solutions</h4>
                <v-layout wrap row>
                  <v-col
                    cols="6"
                    md="4"
                    v-for="(solution, index) in popularSolutions"
                    :key="index"
                  >
                    <v-row>
                      <v-col cols="12" align="center" justify="center">
                        <v-btn
                          class="mx-2 elevation-5"
                          fab
                          dark
                          large
                          color="white"
                        >
                          <v-icon dark size="30" color="#1A237E">
                            {{ solution.icon }}
                          </v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="12">
                        <h6 align="center">{{ solution.text }}</h6>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-layout>
              </v-col>
            </v-layout>

            <v-layout wrap class="mt-4 mb-5">
              <v-col cols="11" md="11" class="whiteBg ml-2">
                <v-layout wrap row>
                  <v-col cols="12" md="5">
                    <v-row align="center">
                      <v-col cols="4" align="right">
                        <v-img
                          alt="Customer Rep"
                          :src="'img/earBud.png'"
                          width="60px"
                        />
                      </v-col>
                      <v-col cols="7" align="left">
                        <h5>Online Service</h5>
                        <p style="font-size:12px;">7 * 24 hours</p>
                        <p>
                          <v-btn rounded outlined small color="#BD2121"
                            >Chat Now</v-btn
                          >
                        </p>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-row align="center">
                      <v-col cols="4" align="right">
                        <v-img
                          alt="Customer Rep"
                          :src="'img/pencilImg.png'"
                          width="60px"
                        />
                      </v-col>
                      <v-col cols="7" align="left">
                        <h5>Your Suggestions</h5>
                        <p style="font-size:12px;">to qikPharma</p>
                        <p>
                          <v-btn rounded outlined small color="#BD2121"
                            >Leave Feedback</v-btn
                          >
                        </p>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-layout>
              </v-col>
            </v-layout>
          </v-col>
        </v-row>
      </v-col>
    </v-layout>
  </v-container>
</template>

<script>
import RetailerMenu from "../others/RetailerMenu.vue";

export default {
  name: "HelpCenter",

  components: {
    RetailerMenu,
  },

  data: () => ({
    breadCrumbsItems: [
      {
        text: "Home",
        disabled: false,
        href: "/#/overview/retailer_dashboard/home",
      },
      {
        text: "Help Center",
        disabled: true,
        href: "/",
      },
    ],
    userDetail: {},
    systemSetting: {
      systemCurrency: "₦",
    },
    quickFAQs: ["Not recieved goods", "refund", "Not satified with products"],
    FAQs: [
      {
        id: 1,
        question: "I have not recieved goods. What can i do?",
        answer:
          "You can get to the Distributors's store and drop a chat for the distributor.",
      },
      {
        id: 2,
        question: "I cannot find order in my account",
        answer: "Orders are processed based on Locations.",
      },
    ],
    popularSolutions: [
      {
        icon: "mdi-clipboard-list-outline",
        text: "Find Order & Account back",
      },
      {
        icon: "mdi-shield-check-outline",
        text: "Change Password",
      },
      {
        icon: "mdi-credit-card-check",
        text: "Payment Appeal",
      },
      {
        icon: "mdi-email-outline",
        text: "Change Email Address",
      },
      {
        icon: "mdi-credit-card",
        text: "Manage Cards",
      },
      {
        icon: "mdi-map-marker-outline",
        text: "Manage Shipping Address",
      },
    ],
  }),
  mounted() {
    this.userDetail = this.$auth.getAuthenticatedUser();
  },
  methods: {},
};
</script>

<style scoped>
.rowBg {
  background-color: #f2f2f2;
  border-radius: 10px;
  padding: 0px 0px 0px 10px;
}

.paddingExtra {
  padding: 20px;
}

.whiteBg {
  background-color: #ffffff;
}

.earningStyle {
  border: 1px solid #c4c4c4;
  border-radius: 10px;
}

.iconStyle {
  font-size: 40px;
  color: #dd6464;
  transform: rotate(180deg);
}

.codeStyle {
  border: 1px solid #c2c2c2;
  border-radius: 10px;
}
</style>
